import { Component } from 'react'
import './CssRoll.scss'
import { displayIncomeApi, BaseImageApi } from '../../request/api'
import {changeToStar} from "../../data/utils";

export default class Roll extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: [],
			count: 0,
			imgUrl: ''
		}
	}

	// 页面挂载时开启定时器
	componentDidMount = () => {
		displayIncomeApi().then(res => {
			if (res.code === 200) {
				this.setState({
					list: res.data
				})
			}
		})
		BaseImageApi().then(res => {
			if (res.code === 200) {
				if (res.data.length > 0) {
					for (let info of res.data) {
						if (info.obType === '2') {
							this.setState({
								imgUrl: info.imgUrls
							})
							break;
						}
					}
				}

			}
		})
	}

	render() {
		const {label, themeValue} = this.props
		return (
			<div className={`list`}>
				<div className={`rowUp`}>
					{this.state.list.map((item, index) => {
						return (
							<div className={`item ${themeValue === 'dark' ? 'cssRollItemDark' : 'cssRollItemLight'}`} key={index}>
								<div className={`itemLeft`}>
									<img src={item.avatar ? item.avatar : this.state.imgUrl} alt="avatar" />
									<span>{changeToStar(item.nickName, 2, 2)}</span>
								</div>
								<div className={`itemRight`}>
									<span>{label}: </span>
									{/* <div className="amount"> $ {item.amount === 'number' ? item.amount.toFixed(2) : item.amount}</div> */}
									<div className={`amount`}> $ {item.amount.toFixed(2)}</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		)
	}
}
