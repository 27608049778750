export const themeKey = 'theme'

export const defaultTheme = 'dark'
export const channelIdKey = 'app:channel:id'
export const grabOrderAnimationTimeKey = "grab:order:animation:time"
export const homeNoticeDialogKey = "home:notice:dialog"
export const appDownloadLinkKey = "app.download.link"
export const appTimeZoneKey = "app.timezone"
export const logoUrlKey = "logo:url"
export const hideAddTradeAccountBtnKey = "hide.add.trade.account"
export const hideEditTradeAccountBtnKey = "hide.edit.trade.account"
export const appDefaultAreaCodeKey = "app.default.area.code"
export const hideInvitationCodeKey = "hide.invitation.code"

export const withdrawFreeRateKey = "withdraw.fee"
export const notAllowedModifyProfileKey = "not.allowed.modify.profile"
export const useIChatChannelIds = [1, 3, 16, 15, 24, 22, 23, 25, 28, 30, 37, 39, 40]
export const notAllowedCancelGrabOrderChannelIds = [13, 37, 36, 39]
export const hideTeamReportChannelIds = [13, 16, 24, 26, 29, 39, 40, 29, 41]
export const hideFinancialMenuChannelIds = [0, 13, 31, 36, 29, 41]
export const hideInviteMenuChannelIds = [13, 26, 29, 31, 32, 29, 41, 3]
export const hideInviteCodeChannelIds = [13, 29, 31, 41, 3]

export const hideModifyUserInfoChannelIds = [0, 31]

export const showQrcodeImgByRegPageChanneIds = [0, 32]

export const hideDepositRecordTimeChannelIds = [0, 29, 41]

export const hideWithdrawRecordTimeChannelIds = [0, 29, 41]

export const modifySafePasswordNeedAuthChannelIds = [0]

export const hideAssetsByAccountPageChannelIds = [35, 4]

export const showIdTextChannelIds = [31]

export const showBonusApplicationTextChannelIds = []
export const hideDownloadAppChannelIds = [29, 35, 3]
export const hideCustomerServiceWorkTimeIds = [13, 18]
export const hideModifyLoginPasswordMenuIds = [13, 25]

export const showOnlineCountChannelIds = []

export const showWithdrawFreeRateChannelIds = [0, 19]

export const showGrabNextLevelTipChannelIds = [0, 19]
export const hideCustomerServiceByLoginPage = [13]

export const hideTeamSubMembersRegTime = [15, 37, 28, 35, 4]

export const hideGrabOrderTimeChannelIds = [0, 36, 29, 41]

export const hideGrabOrderTimeByCompleteChannelIds = [0, 29, 41]

export const hideAccountDetailsTimeChannelIds = [0, 29, 41]

export const hideDialogNoticeChannelIds = [0, 36]

export const hideHomeIntroductionChannelIds = [0, 36]

export const hideAllTopMenuChannelIds = [0, 36]

export const hideHomeIncomeChannelIds = [36]

export const showWhiteBgTopMenuChannelIds = [0, 36]

export const showRegPhoneNumberChannelIds = [35, 4]

export const specifyShowLanguagesByChannel20 = ['en', 'zh-Hans', 'vie', 'ko-kr', 'jp', 'in']

export const specifyShowLanguagesByChannel23 = ['en', 'zh-Hans', 'vie', 'ko-kr', 'jp', 'in', 'ind']

export const specifyShowLanguagesByChannel26 = ['en', 'zh-Hans', 'vie', 'ko-kr', 'jp', 'ind', 'zh-Hant']

export const noModificationSafePasswordChannelIds = [25]

export const limitDepositAndWithdrawRecordChannelIds = []
