import {
	IonCard,
	IonCol,
	IonContent,
	IonHeader,
	IonImg,
	IonPage,
	useIonLoading,
	IonRouterLink,
	IonRow,
	IonToolbar,
	IonToast,
	IonGrid, IonInfiniteScroll, IonInfiniteScrollContent, useIonRouter, IonButtons, IonButton, IonIcon
} from '@ionic/react'
import styles from './Record.module.scss'

import { useTranslation } from 'react-i18next'

import {GetGrabOrderApi, SubmitOrderApi, InfoApi} from '../../request/api'
import { useEffect, useState } from 'react'
import {useHistory, useLocation} from 'react-router'
import {useRaceConditions} from "../../hooks";
import {formatTimeByTimezone} from "../../data/utils";
import {
	channelIdKey,
	defaultTheme, hideGrabOrderTimeByCompleteChannelIds,
	hideGrabOrderTimeChannelIds, showBonusApplicationTextChannelIds,
	themeKey
} from "../../data/constant";
import {notifications} from "ionicons/icons";
const Record = () => {

	const router = useIonRouter()
	const { pathname } = useLocation()  // 存储当前路由地址 当路由变化时候 重新构建

	const { t, i18n } = useTranslation('lang')
	const [orderArr, setOrderArr] = useState([])
	const [pageNum, setPageNum] = useState(1)
	const [pageSize, setPageSize] = useState(10)
	const [noDataStatus, setNoDataStatus] = useState(false)
	const [assets, setAssets] = useState({})
	const [showToast, setShowToast] = useState(false)
	const [present, dismiss] = useIonLoading();
	const [color, setColor] = useState('primary')
	const [recordStatus, setRecordStatus] = useState('0');

	const [showGrabOrderTime, setShowGrabOrderTime] = useState(true)

	const [showGrabOrderTimeByComplete, setShowGrabOrderTimeByComplete] = useState(true)

	const [showBonusApplicationText, setShowBonusApplicationText] = useState(false)

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	const tabsData = [
		{
			status: '0',
			name: t('record.status-processing')
		},
		{
			status: '2',
			name: t('record.status-completed')
		},
		{
			status: '1',
			name: t('record.status-freezing')
		}
	]

	const history = useHistory()
	useEffect(() => {
		if (localStorage.getItem('Authorization') === null) history.push('/login')
	}, [])

	useRaceConditions(() => {
		getMemberInfo()
		getGrabOrderRecord(recordStatus, pageNum)
		isCanShowBonusApplication()
		isCanShowGrabOrderTime()
	}, [pathname])

	const radioChange = status => {
		if (status === recordStatus) return
		setPageNum(1)
		setOrderArr([])
		setRecordStatus(status)
		getGrabOrderRecord(status, pageNum)
	}

	const getMemberInfo = () => {
		InfoApi().then(res => {
			if (res.code === 200) {
				setAssets(res.data.assets)
			}
		})
	}

	const getGrabOrderRecord = (status, pageNum) => {
		GetGrabOrderApi({status: status, pageNum: pageNum, pageSize: pageSize}).then(res => {
			dismiss().then(_ => {})
			if (res.code === 200) {
				const { data } = res
				let lang = localStorage.getItem('language-ind')
				data.map(item => {
					const goodsNameJson = JSON.parse(item.goodsName)
					item.showName = goodsNameJson[lang]
					return item
				})
				if (data.length > 0) {
					if (pageNum === 1) {
						setOrderArr(data)
					} else {
						setOrderArr(orderArr.concat(data))
					}
					setNoDataStatus(false)
				} else {
					if (pageNum === 1) setOrderArr([])
					setNoDataStatus(true)
				}
			}
		})
	}

	const loadData = (event) => {
		setTimeout(() => {
			event.target.complete()
			if (noDataStatus) {
				event.target.disabled = true
			} else {
				setPageNum(pageNum + 1)
				getGrabOrderRecord(recordStatus, pageNum + 1)
			}
		}, 500)
	}

	const loadingData = () => {
		present().then(_ => {})
	}

	const submitOrder = id => {
		loadingData()
		SubmitOrderApi({ id }).then(res => {
			dismiss().then(_ => {})
			if (res.code === 200) {
				setShowToast(true)
				setColor('primary')
				getMemberInfo()
				setPageNum(1)
				getGrabOrderRecord(recordStatus, 1)
			}
		})
	}

	const isCanShowGrabOrderTime = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (hideGrabOrderTimeChannelIds.indexOf(Number(channelId)) > -1) {
			setShowGrabOrderTime(false)
		} else {
			setShowGrabOrderTime(true)
		}

		if (hideGrabOrderTimeByCompleteChannelIds.indexOf(Number(channelId)) > -1) {
			setShowGrabOrderTimeByComplete(false)
		} else {
			setShowGrabOrderTimeByComplete(true)
		}
	}

	const isCanShowBonusApplication = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (showBonusApplicationTextChannelIds.indexOf(Number(channelId)) > -1) {
			setShowBonusApplicationText(true)
		} else {
			setShowBonusApplicationText(false)
		}
	}

	return (
		<IonPage className={styles.recordPage}>
			<IonHeader className={`${themeValue === 'dark' ? styles.headerDark : styles.headerLight}`}>
				<IonToolbar>
					<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonCol size="2" />
						<IonCol size="8">
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.headerTitleDark : styles.headerTitleLight}`}>{t('record.title')}</h4>
						</IonCol>
						<IonCol size="2">
							<IonButtons>
								<IonButton onClick={() => {
									router.push('/system-notice')
								}}>
									<IonIcon slot={'icon-only'} icon={notifications}/>
								</IonButton>
							</IonButtons>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen className="ion-padding">
				<div className={styles.topBox}>
					<div className={styles.topTabs}>
						{tabsData.length > 0 && tabsData.map((item, index) => {
							return (
								<div key={index} className={styles.topTabsItem} onClick={() => {
									radioChange(item.status)
								}}>
									<div className={`${styles.topTabsItemContent} ${recordStatus === item.status ? themeValue === 'dark' ? styles.topTabsItemContentActiveDark : styles.topTabsItemContentActiveLight :
										themeValue === 'dark' ? styles.topTabsItemContentNoActiveDark : styles.topTabsItemContentNoActiveLight}`}>{item.name}</div>
								</div>
							)
						})}
					</div>
					<div className={`${styles.availableBalanceBox}}`}>
						<span className={`${themeValue === 'dark' ? styles.availableBalanceBoxDark : styles.availableBalanceBoxLight}`}>{t('record.account-amount')}:</span>
						<IonRow>
							<h2 className={`main-number ${styles.availableBalanceLabel} ${themeValue === 'dark' ? styles.availableBalanceBoxDark : styles.availableBalanceBoxLight}`}>$ {typeof assets.availableBalance === 'number' ? assets.availableBalance.toFixed(2) : assets.availableBalance}</h2>
							<div style={{'width':  '30px',}}></div>
							{
								showBonusApplicationText &&
								<h3 style={{ 'color': 'red'}} className={`main-number ${styles.bonusApplication}`}>{t('record.bonus-application')}</h3>
							}
						</IonRow>

					</div>
				</div>

				<IonGrid className="ion-padding-start ion-padding-end">
					{orderArr.map((item, index) => {
						return (
							<IonCard className={`main-radius ion-no-margin ion-padding ${themeValue === 'dark' ? styles.cardBgDark : styles.cardBgLight}`} key={index}>
								<IonRow className="ion-justify-content-between">
									{showGrabOrderTime && <span>{formatTimeByTimezone(item.createTime)}</span>}
									{!showGrabOrderTimeByComplete && item.status !== '2' && <span>{formatTimeByTimezone(item.createTime)}</span>}
									{item.status === '0' && <span className={`${styles.recordStatus} ${styles.recordStatus0}`}>{t('record.status-processing')}</span>}
									{item.status === '1' && <span className={`${styles.recordStatus} ${styles.recordStatus1}`}>{t('record.status-freezing')}</span>}
									{item.status === '2' && <span className={`${styles.recordStatus} ${styles.recordStatus2}`}>{t('record.status-completed')}</span>}
								</IonRow>
								<IonRow className={`ion-align-items-center ${styles.productInfo}`}>
									<IonCol size="3">
										<IonImg src={item.goodsPicUrl} alt="product" />
									</IonCol>
									<IonCol size="9">
										<h6 className="">{item.showName}</h6>
									</IonCol>
								</IonRow>
								<IonRow >
									<span className={styles.cardInfoItem}>{t('record.order-amount')}</span>
									<span className="main-number">$ {item.goodsPrice.toFixed(2)}</span>
								</IonRow>
								<IonRow >
									<span className={styles.cardInfoItem}>{t('record.commission')}</span>
									<span className="main-number">$ {item.commissionAmount.toFixed(2)}</span>
								</IonRow>
								<IonRow>
									<span className={styles.cardInfoItem}>{t('record.returned-amount')}</span>
									<span className="main-number">$ {item.tradeAmount.toFixed(2)}</span>
								</IonRow>
								{item.status === '0' ? <div
									onClick={() => submitOrder(item.id)}
									className={`ion-margin-top ion-margin-bottom ${themeValue === 'dark' ? styles.submitBtnDark : styles.submitBtnLight}`}
									expand="block"
								>
									{t('order.submit-order')}
								</div> : ''}
							</IonCard>
						)
					})}
					{orderArr.length === 0 ? <h4 style={{ textAlign: 'center', color: themeValue === 'dark' ? 'white': 'black' }}>{t('common-text.no-data')}</h4> : null}
				</IonGrid>
				<IonInfiniteScroll threshold="100px" distance="1%" onIonInfinite={(e) => loadData(e)}>
					<IonInfiniteScrollContent loadingSpinner="bubbles" loadingText={t('common-text.load-more-data')}>
					</IonInfiniteScrollContent>
				</IonInfiniteScroll>
			</IonContent>
			<IonToast
				isOpen={showToast}
				message="Successed submit"
				duration={2000}
				color={color}
				position={'top'}
			/>
		</IonPage>
	)
}

export default Record
