import {
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonPage,
	IonRow,
	IonToolbar,
	IonList,
	IonRadioGroup,
	IonLabel,
	IonItem,
	IonRadio,
	IonToast,
	IonSelect,
	IonSelectOption, IonIcon
} from '@ionic/react'
import styles from './Bank.module.scss'

import SignupField from '../../components/old-signup/SignupField'
import { chevronBackOutline } from 'ionicons/icons'
import { BindBank } from '../../data/fields'
import { useEffect, useState } from 'react'
import { validateForm } from '../../data/utils'
import {useParams, useHistory, useLocation} from 'react-router'

import { useTranslation } from 'react-i18next'

import { AddAccountApi, BusinessBankApi, AccountInfoApi, ModifyAccountApi } from '../../request/api'
import BottomLine from '../../components/bottom-line/BottomLine'
import qs from "qs";
import {defaultTheme, themeKey} from "../../data/constant";

const Bank = () => {
	const url = window.location.search
	const { t, i18n } = useTranslation('lang')
	const location = useLocation()
	const params = useParams()
	const fields = BindBank()
	const [errors, setErrors] = useState(false)

	const [accountType, setAccountType] = useState('2')
	const [chainType, setChainType] = useState('TRC20')

	const [showToast, setShowToast] = useState(false)
	const [showToast2, setShowToast2] = useState(false)
	const [message, setMessage] = useState('')
	const [bankArr, setBankArr] = useState([])
	const [bankSelected, setBankSelected] = useState('brown')

	const paramStr = location.search.slice(1)
	const paramJson = qs.parse(paramStr)

	const history = useHistory()

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	useEffect(() => {
		if (localStorage.getItem('Authorization') === null) history.push('/login')
		if (paramJson.id) {
			AccountInfoApi({id: paramJson.id}).then(res => {
				if (res.code === 200) {
					const { accountType, address, chainType, bankCode, phoneNumber, pix, realName } = res.data
					if (accountType === '2') {
						fields[3].input.state.reset(address)
						setChainType(chainType)
					} else if (accountType === '1') {
						fields[2].input.state.reset(pix)
						setBankSelected(bankCode)
					}
					fields[0].input.state.reset(realName)
					fields[1].input.state.reset(phoneNumber)
					setAccountType(accountType)
				}
			})
		}
		getBankArr()
	}, [])

	const getBankArr = () => {
		BusinessBankApi().then(res => {
			if (res.code === 200) {
				const { data } = res
				const lang = localStorage.getItem('language-ind') || 'vie'
				data.map(item => {
					try {
						const bankNameJson = JSON.parse(item.bankName)
						item.showName = bankNameJson[lang]
						return item
					} catch (err) {
						return item
					}
				})
				setBankArr(data)
			}
		})
	}

	const changeAddress = () => {
		const errors = validateForm(fields, t('common-text.check-filed-prefix'))
		setErrors(errors)
		if (!errors.length || (errors.length === 1)) {
			let data
			if (accountType === '2') {
				data = {
					accountType: '2',
					address: fields[3].input.state.value,
					chainType: chainType,
					realName: fields[0].input.state.value,
				}
			} else {
				let bankNameIndex = bankArr.findIndex(item => item.bankCode === bankSelected)
				let bankName = bankArr[bankNameIndex].bankName
				data = {
					accountType: '1',
					bankCode: bankSelected,
					bankName,
					pix: fields[2].input.state.value,
					realName: fields[0].input.state.value,
				}
			}
			addOrEditAccount(data)
		}
	}

	const addOrEditAccount = data => {
		if (url) {
			if (paramJson.id) {
				const reqData = { ...data, ...{ id: paramJson.id } }
				ModifyAccountApi(reqData).then(res => {
					if (res.code === 200) {
						setMessage(res.msg)
						setShowToast(true)
						goBack()
					} else {
						setMessage(res.msg)
						setShowToast2(true)
					}
				})
			}

		} else {
			AddAccountApi(data).then(res => {
				if (res.code === 200) {
					setMessage(res.msg)
					setShowToast(true)
					goBack()
				} else {
					setMessage(res.msg)
					setShowToast2(true)
				}
			})
		}
	}

	const changeAccountType = e => {
		let value = e.detail.value
		setAccountType(value)
	}

	const changeChainType = e => {
		let value = e.detail.value
		setChainType(value)
	}

	useEffect(() => {
		return () => {
			fields.forEach(field => field.input.state.reset(''))
			setErrors(false)
		}
	}, [params])

	const goBack = () => {
		history.goBack()
	}

	return (
		<IonPage className={styles.bankPage}>
			<IonHeader className={`${themeValue === 'dark' ? styles.headerDark : styles.headerLight}`}>
				<IonToolbar>
					<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonCol size="2">
							<IonButtons>
								<IonButton onclick={() => goBack()}>
									<IonIcon style={{color: themeValue === 'dark' ? 'white' :'black'}} slot={'icon-only'} icon={chevronBackOutline}/>
								</IonButton>
							</IonButtons>
						</IonCol>
						<IonCol size="8">
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.headerTitleDark : styles.headerTitleLight}`}>{t('bank.title')}</h4>
						</IonCol>
						<IonCol size="2"/>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen className="ion-padding">
				<IonGrid className="ion-padding">
					<IonRow>
						<IonCol size="12">
							<SignupField field={fields[0]} errors={errors} />
							<SignupField field={fields[1]} errors={errors} />
							<p className={`${styles.selectTitle} ${themeValue === 'dark' ? styles.selectTitleDark : styles.selectTitleLight}`}>{t('bank.choose')}</p>
							<IonItem className={`${styles.selectOptions} ${themeValue === 'dark' ? styles.selectItemDark : styles.selectItemLight}`}>
								<IonLabel>{t('bank.options')}</IonLabel>
								<IonSelect
									mode="ios"
									okText={t('common-text.confirm')}
									cancelText={t('common-text.cancel')}
									value={accountType}
									onIonChange={changeAccountType}
									className={`${themeValue === 'dark' ? styles.selectItemDark : styles.selectItemLight}`}
								>
									<IonSelectOption className={`${themeValue === 'dark' ? styles.selectItemDark : styles.selectItemLight}`} value="2">
										{t('bank.WalletAddress')}
									</IonSelectOption>
									<IonSelectOption className={`${themeValue === 'dark' ? styles.selectItemDark : styles.selectItemLight}`} value="1">
										{t('bank.BankCard')}
									</IonSelectOption>
								</IonSelect>
							</IonItem>

							{accountType === '2' ? (
								<IonList>
									<IonRadioGroup value={chainType} onIonChange={changeChainType} mode="ios" color='success'>
										<div className={`${styles.type} ${themeValue === 'dark' ? styles.typeDark : styles.typeLight}`}>{t('bank.chain-type')}</div>
										<IonItem
											className={`${styles.selectItem} ${themeValue === 'dark' ? styles.selectItemDark : styles.selectItemLight}`}
											color="light"
											style={{
												marginBottom: '10px'
											}}
										>
											<IonLabel>{t('bank.chain-trc')}</IonLabel>
											<IonRadio slot="start" value="TRC20" />
										</IonItem>

										<IonItem className={`${styles.selectItem} ${themeValue === 'dark' ? styles.selectItemDark : styles.selectItemLight}`} color="light" style={{
											marginBottom: '10px'
										}}>
											<IonLabel>{t('bank.chain-erc')}</IonLabel>
											<IonRadio slot="start" value="ERC20" />
										</IonItem>
									</IonRadioGroup>
								</IonList>
							) : (
								<IonItem className={`${styles.radioItem} ${themeValue === 'dark' ? styles.selectItemDark : styles.selectItemLight}`} >
									<IonLabel>{t('bank.choose-bank')}</IonLabel>
									<IonSelect
										value={bankSelected}
										mode="ios"
										okText={t('common-text.confirm')}
										cancelText={t('common-text.cancel')}
										onIonChange={e => {
											setBankSelected(e.detail.value)
										}}
										className={`${themeValue === 'dark' ? styles.selectItemDark : styles.selectItemLight}`}
									>
										{bankArr.map((item, index) => {
											return (
												<IonSelectOption className={`${themeValue === 'dark' ? styles.selectItemDark : styles.selectItemLight}`} value={item.bankCode} key={index}>
													{item.showName}
												</IonSelectOption>
											)
										})}
									</IonSelect>
								</IonItem>
							)}
							<SignupField field={accountType !== '2' ? fields[2] : fields[3]} errors={errors} />
							<IonButton
								className={`custom-button ion-padding-top ion-margin-bottom ${themeValue === 'dark' ? styles.bankSubmitBtnDark : styles.bankSubmitBtnLight}`}
								expand="block"
								onClick={changeAddress}
							>
								{t('shipping.confirm')}
							</IonButton>
						</IonCol>
					</IonRow>
					<div className=" ion-padding-top">
						<p style={{color: themeValue === 'dark' ? 'white' :'black'}}>{t('bank.tip')}:</p>
						<p className={styles.tipComment}>{t('bank.comment')}</p>
					</div>
				</IonGrid>
			</IonContent>
			<BottomLine />
			<IonToast
				isOpen={showToast}
				onDidDismiss={() => {setShowToast(false)}}
				message={message}
				duration={1000}
				color="primary"
				position={'top'}
			/>
			<IonToast
				isOpen={showToast2}
				onDidDismiss={() => {setShowToast2(false)}}
				message={message}
				duration={2000}
				color="danger"
				position={'top'}
			/>
		</IonPage>
	)
}

export default Bank
