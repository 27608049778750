import {
	IonCard,
	IonCol,
	IonContent,
	IonHeader,
	IonImg,
	IonPage,
	IonRouterLink,
	useIonLoading,
	IonRow,
	IonToolbar,
	IonButton,
	IonToast, IonButtons, IonIcon, useIonRouter
} from '@ionic/react'
import styles from './Order.module.scss'

import { useTranslation } from 'react-i18next'
import OrderSlider from '../../components/slider/OrderSlider'
import Dialog from '../../components/dialog/Dialog'
import { useState, useEffect } from 'react'

import {GrabOrderApi, SubmitOrderApi, InfoApi, PresetOrderApi, LevelApi, getLastGrabOrderInfo} from '../../request/api'

import {formatTimeByTimezone, setShowName} from '../../data/utils'
import {useHistory, useLocation} from 'react-router'
import alram from './hongbao.mp3'
import {BallFall} from "react-pretty-loading";
import {
	channelIdKey, defaultTheme,
	grabOrderAnimationTimeKey, hideGrabOrderTimeChannelIds,
	notAllowedCancelGrabOrderChannelIds,
	showGrabNextLevelTipChannelIds, themeKey
} from "../../data/constant";
import {notifications} from "ionicons/icons";
const Order = () => {

	const router = useIonRouter()
	const { pathname } = useLocation()  // 存储当前路由地址 当路由变化时候 重新构建

	const [grabOrderStatus, setGrabOrderStatus] = useState(0)

	const { t, i18n } = useTranslation('lang')
	const [slider, setSlider] = useState(false)
	const [sliderLoop, setSliderLoop] = useState(false)
	const [present, dismiss] = useIonLoading();
	const [showDialog, setShowDialog] = useState(false)
	const [showCancelOrderBtn, setShowCancelOrderBtn] = useState(false)
	const [animationList, setAnimationList] = useState([])
	const [orderData, setOrderData] = useState({})
	const [assets, setAssets] = useState({})
	const [memberInfo, setMemberInfo] = useState({})
	const [presetArr, setPresetArr] = useState([])
	const [levelArr, setLevelArr] = useState([])
	const [myLevel, setMyLevel] = useState({})
	const [animationLoadingStatus, setAnimationLoadingStatus] = useState(false)
	const [luckyBoxStatus, setLuckyBoxStatus] = useState(false)
	const [maskBoxStatus, setMaskBoxStatus] = useState(false)
	const [showLuckyBoxTime, setShowLuckyBoxTime] = useState(0)
	const [commissionRate, setCommissionRate] = useState(0)

	const [showGrabNextLevelTip, setShowGrabNextLevelTip] = useState(false)

	const [showGrabOrderTime, setShowGrabOrderTime] = useState(true)

	const [showToast, setShowToast] = useState(false)
	const [message, setMessage] = useState('')
	const [color, setColor] = useState('danger')
	const history = useHistory()
	const audio = new Audio(alram)

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	useEffect(() => {
		if (localStorage.getItem('Authorization') === null) history.push('/login')
	}, [])

	const beginOrder = () => {
		GrabOrderApi().then(res => {
			setMaskBoxStatus(false)
			hideAnimationLoading()
			dismiss().then(_ => {})
			if (res.code === 200) {
				const {goodsListByAnimation, toBeProcessedRecord, animationResult } = res.result
				goodsListByAnimation &&
					goodsListByAnimation.map(item => {
						return setShowName(item, 'title')
					})
				setOrderData(setShowName(animationResult || toBeProcessedRecord, 'goodsName'))
				setAnimationList(goodsListByAnimation || [])
				if (Number(assets.lockBalance)) {
					setShowDialog(true)
					isCanShowCancelOrderBtn()
					isCanShowGrabOrderTime()
				} else {
					// showLuckyBox()
					// hideLuckyBox(false)
					setShowDialog(true)
					isCanShowCancelOrderBtn()
					isCanShowGrabOrderTime()
				}
				audio.play().then(r => {})
				getMemberInfo()
			} else {
				setShowToast(false)
				setMessage(res.msg)
				setColor('danger')
				setShowToast(true)
				setShowDialog(false)
			}
		}).catch(r => {
			hideAnimationLoading()
		})
	}

	const isCanShowCancelOrderBtn = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (notAllowedCancelGrabOrderChannelIds.indexOf(Number(channelId)) > -1) {
			setShowCancelOrderBtn(false)
		} else {
			setShowCancelOrderBtn(true)
		}
	}

	const showAnimationLoading = () => {
		// 如果有冻结金额 就不需要延迟动画时间
		getLastGrabOrderInfo().then(res => {
			if (res.code === 200) {
				if (!Number(assets.lockBalance) && res.data) {
					beginOrder()
				} else {
					setMaskBoxStatus(true)
					setAnimationLoadingStatus(true)
					// 获取加载动画时间
					const grabOrderAnimationTime = localStorage.getItem(grabOrderAnimationTimeKey) || 5000;
					const playTimes = Number(grabOrderAnimationTime) - showLuckyBoxTime
					setTimeout(() => {
						beginOrder()
					}, playTimes)
					setGrabOrderStatus(1)
					setTimeout(() => {
						setGrabOrderStatus(2)
						setTimeout(() => {
							setGrabOrderStatus(3)
						}, (playTimes - playTimes/2) / 2)
					}, playTimes/2)
				}
			}
		})
	}

	const showLuckyBox = () => {
		setLuckyBoxStatus(true)
	}

	const hideLuckyBox = (hasLockBalance) => {
		setTimeout(() => {
			setLuckyBoxStatus(false)
			if (!hasLockBalance) {
				setShowDialog(true)
				isCanShowCancelOrderBtn()
			}
		}, showLuckyBoxTime)
	}

	const hideAnimationLoading = () => {
		setAnimationLoadingStatus(false)
	}

	const loadingData = () => {
		present().then(r => {})
	}

	const submitOrder = () => {
		SubmitOrderApi({ id: orderData.id }).then(res => {
			if (res.code === 200) {
				setShowDialog(false)
				setMessage(res.msg)
				setColor('success')
				setShowToast(true)
				getMemberInfo()
			} else {
				setShowDialog(false)
				setMessage(res.msg)
				setColor('danger')
				setShowToast(true)
			}
		})
	}

	useEffect(() => {
		setGrabNextLevelTipBox()
		getMemberInfo()
		PresetOrderApi().then(res => {
			dismiss().then(_ => {})
			if (res.code === 200) {
				let lang = localStorage.getItem('language-ind')
				if (lang === "") lang = "vie"
				res.goodsList.map((item, index) => {
					res.goodsList[index].title = JSON.parse(item.title)[lang]
				})
				setPresetArr(res.goodsList)
				setTimeout(() => {
					setSlider(true)
					setSliderLoop(true)
				}, 100)
			}
		})
	}, [pathname])


	const getMemberInfo = () => {
		let isSetCommissionRate = false
		InfoApi().then(res => {
			if (res.code === 200) {
				const { member, assets } = res.data
				setMemberInfo(member)
				setAssets(assets)

				if (member) {
					// console.log(member)
					if(member.commissionRate > 0) {
						setCommissionRate(member.commissionRate)
						isSetCommissionRate = true
					}
				}

				LevelApi().then(res => {
					if (res.code === 200) {
						setLevelArr(res.data)
						if (res.data.length > 0) {
							for (let info of res.data) {
								if (info.id === member.memberLevelId || info.orderNum === member.memberLevelIndex) {
									setMyLevel(info)
									if (!isSetCommissionRate) {
										setCommissionRate(info.commissionRate)
									}
									break
								}
							}
						}
					}
				})
			}
		})
	}

	const setGrabNextLevelTipBox = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (showGrabNextLevelTipChannelIds.indexOf(Number(channelId)) > -1) {
			setShowGrabNextLevelTip(true)
		} else {
			setShowGrabNextLevelTip(false)
		}
	}

	const isCanShowGrabOrderTime = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (hideGrabOrderTimeChannelIds.indexOf(Number(channelId)) > -1) {
			setShowGrabOrderTime(false)
		} else {
			setShowGrabOrderTime(true)
		}
	}

	return (
		<IonPage className={styles.orderPage}>
			{showDialog && (
				<Dialog hideDialog={() => setShowDialog(!showDialog)} hide={false}>
					<div style={{ padding: '10px' }}>
						<h3>{t('order.order-submission')}</h3>
						{showGrabOrderTime && <p>{t('order.order-time')}:&nbsp;{formatTimeByTimezone(orderData.createTime)}</p>}
						<p>{t('order.order-number')}:&nbsp;{orderData.orderSn}</p>
						<div style={{ display: 'flex', alignItems: 'center', textTransform: 'none !important' }}>
							<img src={orderData.goodsPicUrl} style={{ width: '77px', height: '77px', padding: '10px' }} />
							<div style={{ padding: '10px', fontSize: '12px' }}>{orderData.showName}</div>
						</div>
						<IonRow style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px', marginTop: '10px' }}>
							<div>{t('order.total-order')}</div>
							<div>{orderData.goodsPrice.toFixed(2)}</div>
						</IonRow>
						<IonRow style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px' }}>
							<div>{t('order.commission')}</div>
							<div>{orderData.commissionAmount.toFixed(2)}</div>
						</IonRow>
						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
							{showCancelOrderBtn &&
								<div
									onClick={() => setShowDialog(false)}
									style={{ padding: '8px 15px', fontSize: '18px', marginRight: '10px', borderRadius: '30px' }}
									className={`${themeValue === 'dark' ? styles.cancelBtnDark : styles.cancelBtnLight}`}
								>
									{t('order.cancel')}
								</div>
							}
							<div
								onClick={submitOrder}
								style={{
									padding: '8px 15px',
									fontSize: '18px',
									marginLeft: '10px',
									borderRadius: '30px',
									color: '#fff'
								}}
								className={`${themeValue === 'dark' ? styles.okBtnDark : styles.okBtnLight}`}
							>
								{t('order.submit-order')}
							</div>
						</div>
					</div>
				</Dialog>
			)}
			<IonHeader className={`${themeValue === 'dark' ? styles.headerDark : styles.headerLight}`}>
				<IonToolbar>
					<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonCol size="2"/>
						<IonCol size="8">
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.headerTitleDark : styles.headerTitleLight}`}>{t('order.title')}</h4>
						</IonCol>
						<IonCol size="2">
							<IonButtons>
								<IonButton onClick={() => {
									router.push('/system-notice')
								}}>
									<IonIcon slot={'icon-only'} icon={notifications}/>
								</IonButton>
							</IonButtons>
						</IonCol>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<div className={styles.luckyBox} style={{display: luckyBoxStatus ? 'block' : 'none', position: 'absolute',  zIndex: 400}}>
				<div className={styles.luckyBoxBg}>
					<img className={styles.luckyBoxBgContent} src={'/assets/gif/lucky_box.gif'}/>
				</div>
			</div>
			<div className={styles.maskBox} style={{display: maskBoxStatus ? 'block' : 'none', position: 'absolute',  zIndex: 300}}>
			</div>
			<div style={{display: animationLoadingStatus ? 'block' : 'none', transform: 'translate(-50%, -50%)', position: 'absolute', top: '50%', left: '50%', zIndex: 400}}>
				<BallFall loading={true} center width={110} color={'#ffffff'} />
				<div style={{display: grabOrderStatus === 1 ? 'block' : 'none'}} className={styles.grabOrderStatusText1}>{t('order.grab-status-text-1')}</div>
				<div style={{display: grabOrderStatus === 2 ? 'block' : 'none'}} className={styles.grabOrderStatusText2}>{t('order.grab-status-text-2')}</div>
				<div style={{display: grabOrderStatus === 3 ? 'block' : 'none'}} className={styles.grabOrderStatusText3}>{t('order.grab-status-text-3')}</div>
			</div>
			<IonContent fullscreen className="ion-padding">
				<IonCard className={`${styles.orderCard} ${themeValue === 'dark' ? styles.topCardDark : styles.topCardLight}`}>
					<IonRow className={`ion-justify-content-between ion-margin-bottom ${styles.cardDetail}`}>
						<IonImg src={myLevel.iconUrl || '/assets/images/personal/badge.png'} alt="badge" />
						<div>
							<span>{t('order.account-amount')}:</span>
							<h4 className="main-number ion-no-margin">$ {typeof assets.availableBalance === 'number' ? assets.availableBalance.toFixed(2) : assets.availableBalance
							}</h4>
							<IonRow className="ion-align-items-center">
								<span className="ion-margin-end">{myLevel.levelName}</span>
								<span>
									{t('order.commission')} {commissionRate}% | {myLevel.maxOrderNumber} {t('order.order')}
								</span>
							</IonRow>
						</div>
						<IonRow
							className={`ion-justify-content-center ion-margin-bottom ion-align-items-center ion-margin-top ${styles.orderAmount}`}
						>
							<div className={`main-number ${styles.roundedNumber}`}>{assets.grabOrderNumber}</div>
							<span className="main-number"> / </span>
							<span className="main-number">{myLevel.maxOrderNumber}</span>
						</IonRow>
					</IonRow>
					<div className={`${styles.sliderBox}`}>
						{slider ? <OrderSlider list={animationList} arr={presetArr} loop={sliderLoop} /> : ''}
					</div>
				</IonCard>
				<div className={`text-white ${styles.orderList} ${styles.orderCard} ${themeValue === 'dark' ? styles.topOrderListDark : styles.topOrderListLight}`}>
					<div className="d-flex ion-justify-content-between ion-align-items-center" style={{ display: 'flex' }}>
						<span>{t('order.get-commission')}</span>
						<span className="main-number">$ {typeof assets.commissionAmountByToday === 'number' ? assets.commissionAmountByToday.toFixed(2) : assets.commissionAmountByToday}</span>
					</div>
					<div className="d-flex ion-justify-content-between ion-align-items-center" style={{ display: 'flex' }}>
						<span>{t('order.freeze-amount')}</span>
						<span className="main-number">$ {typeof assets.lockBalance === 'number' ? assets.lockBalance.toFixed(2) : assets.lockBalance}</span>
					</div>
					<div className="d-flex ion-justify-content-between ion-align-items-center" style={{ display: 'flex' }}>
						<span>{t('order.quantity-order')}</span>
						<span className="main-number">{assets.grabOrderNumber}</span>
					</div>
					<div className="d-flex ion-justify-content-between ion-align-items-center" style={{ display: 'flex' }}>
						<span>{t('order.enable-amount')}</span>
						<span className="main-number">$ {typeof assets.availableBalance === 'number' ? assets.availableBalance.toFixed(2) : assets.availableBalance}</span>
					</div>
				</div>
				<div onClick={showAnimationLoading} disabled={animationLoadingStatus} className={`custom-button ion-padding-top ion-margin-bottom ${themeValue === 'dark' ? styles.submitBtnDark : styles.submitBtnLight}`} expand="block">
					{t('order.start-order')}
				</div>
				{showGrabNextLevelTip &&
					<div className={`${styles.highLevelTipContent} ${themeValue === 'dark' ? styles.highLevelTipContentDark : styles.highLevelTipContentLight}`}>
						<div className={`${styles.unlockBox} ${themeValue === 'dark' ? styles.unlockBoxDark : styles.unlockBoxLight}`}>{t('ext.unlock')}</div>
						<div style={{paddingLeft: '10px'}}>{t('ext.unlockTip')}</div>
					</div>
				}
			</IonContent>
			<IonToast
				style={{fontSize: '15px'}}
				isOpen={showToast}
				onDidDismiss={() => {setShowToast(false)}}
				message={message}
				duration={2000}
				color={color}
				position={'middle'}
			/>
		</IonPage>
	)
}

export default Order
