import styles from './Home.module.scss'
import {
	IonCol,
	IonContent,
	IonCard,
	IonImg,
	IonGrid,
	IonPage,
	IonRow,
	useIonRouter,
	IonRouterLink
} from '@ionic/react'
import { useTranslation } from 'react-i18next'
import HomeSlider1 from '../../components/home-slider/HomeSlider1'
import Slogan from '../../components/slogan/Slogan'
import DialogNotice from '../../components/dialog-notice/DialogNotice'
import CssRoll from '../../components/css-roll/CssRoll'
import {useEffect, useState} from 'react'
import { InfoApi, LinksImageApi, HomeIntroduceApi, HomeDirectDisplayApi, NoticeApi, LevelApi } from '../../request/api'
import { useHistory } from 'react-router-dom'
import {useLocation} from "react-router";
import {
	channelIdKey, defaultTheme, hideAllTopMenuChannelIds, hideDialogNoticeChannelIds,
	hideFinancialMenuChannelIds, hideHomeIncomeChannelIds, hideHomeIntroductionChannelIds, hideInviteMenuChannelIds,
	homeNoticeDialogKey, showOnlineCountChannelIds, showWhiteBgTopMenuChannelIds, themeKey
} from "../../data/constant";
import AnimateNumber from "../../components/animate-number/AnimateNumber";

//console.log(window.location.protocol + '//' + window.location.hostname)

const Home = () => {
	const history = useHistory()
	const { pathname } = useLocation()  // 存储当前路由地址 当路由变化时候 重新构建
	const { t, i18n } = useTranslation('lang')
	const router = useIonRouter()

	const [linksImage, setLinksImage] = useState([])
	const [introduceArr, setIntroduceArr] = useState([])
	const [introduceArr2, setIntroduceArr2] = useState([])
	const [homeTopText, setHomeTopText] = useState([])
	const [homeNotice, setHomeNotice] = useState('')
	const [memberInfo, setMemberInfo] = useState({})
	const [assets, setAssets] = useState({})
	const [levelArr, setLevelArr] = useState([])
	const [showFinancialMenu, setShowFinancialMenu] = useState(true)
	const [showInviteMenu, setShowInviteMenu] = useState(true)
	const [showIntroduction, setShowIntroduction] = useState(true)
	const [showAllTopMenu, setShowAllTopMenu] = useState(true)
	const [showWhiteBgTopMenu, setShowWhiteBgTopMenu] = useState(false)

	const [showOnlineCount, setShowOnlineCount] = useState(false)

	const [showDialog, setShowDialog] = useState(false)
	const [showModal, setShowModal] = useState(false)

	const [homeDirectDisplayArr, setHomeDirectDisplayArr] = useState([])
	const [homeDirectDisplayArr2, setHomeDirectDisplayArr2] = useState([])

	const [showHomeIncome, setShowHomeIncome] = useState(true)

	const themeValue = localStorage.getItem(themeKey) || defaultTheme


	useEffect(() => {
		if (localStorage.getItem('Authorization') === null) history.push('/login')
	}, [])

	useEffect(() => {
		InfoApi().then(res => {
			if (res.code === 200) {
				setAssets(res.data.assets)
				setMemberInfo(res.data.member)
			}
		})
	}, [pathname])

	useEffect(() => {
		if (pathname !== '/app/home') {
			return
		}
		LinksImageApi().then(res => {
			if (res.code === 200) {
				let list = res.data[0].imgUrls.split(',')
				setLinksImage(list)
			}
		})
	}, [pathname])

	useEffect(() => {
		if (pathname !== '/app/home') {
			return
		}
		HomeDirectDisplayApi().then(res => {
			if (res.code === 200) {
				if (res.data.length > 0) {
					if (res.data.length >= 3) {
						setHomeDirectDisplayArr(res.data.splice(0, 2))
						setHomeDirectDisplayArr2(res.data)
					} else {
						setHomeDirectDisplayArr(res.data)
					}
				} else {
					setHomeDirectDisplayArr([])
					setHomeDirectDisplayArr2([])
				}
			}
		})
	}, [pathname])

	useEffect(() => {
		if (pathname !== '/app/home') {
			return
		}
		HomeIntroduceApi().then(res => {
			if (res.code === 200) {
				if (res.data.length > 0) {
					let introduceArr = []
					let introduceArr2 = []
					let len =  res.data.length
					let maxLeftNumber = 2
					if (len % 2 === 0) {
						maxLeftNumber = len / 2
					}
					for (let i = 0; i < len; i ++) {
						if (i < maxLeftNumber) {
							introduceArr.push(res.data[i])
						} else {
							introduceArr2.push(res.data[i])
						}
					}
					// console.log(introduceArr)
					setIntroduceArr(introduceArr)
					setIntroduceArr2(introduceArr2)
				}
			}
		})
	}, [pathname])

	useEffect(() => {
		if (pathname !== '/app/home') {
			return
		}
		NoticeApi().then(res => {
			if (res.code === 200) {
				const { data } = res
				data.map(item => {
					if (item.noticeType === '3') {
						const originalStr = item.noticeContent
						const originalStrToArr = originalStr.split('</p>')
						const strArr = []
						if (originalStrToArr.length > 0) {
							for (let info of originalStrToArr) {
								if (info) {
									strArr.push(info.replace(/<[^>]+>/ig, ''))
								}
							}
						}
						setHomeTopText(strArr)
					} else if (item.noticeType === '2') {
						setHomeNotice(item.noticeContent)
						if (localStorage.getItem(homeNoticeDialogKey) === 'false') {
							let channelId = localStorage.getItem(channelIdKey)
							if (hideDialogNoticeChannelIds.indexOf(Number(channelId)) > -1) {
								setShowDialog(false)
							} else {
								setTimeout(() => {
									setShowDialog(true)
									localStorage.setItem(homeNoticeDialogKey, 'true')
								}, 500)
							}
						}
					}
				})
			}
		})
	}, [pathname])

	useEffect(() => {
		LevelApi().then(res => {
			if (res.code === 200) {
				setLevelArr(res.data)
			}
		})
	}, [pathname])

	useEffect(() => {
		canShowAllTopMenu()
		canShowWhiteBgTopMenu()
		canShowInviteMenu()
		canShowFinancialMenu()
		canShowHomeIncome()
		canShowIntroduction()
		setShowOnlineCountBox()
	}, [])

	const canShowInviteMenu = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (hideInviteMenuChannelIds.indexOf(Number(channelId)) > -1) {
			setShowInviteMenu(false)
		} else {
			setShowInviteMenu(true)
		}
	}

	const canShowFinancialMenu = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (hideFinancialMenuChannelIds.indexOf(Number(channelId)) > -1) {
			setShowFinancialMenu(false)
		} else {
			setShowFinancialMenu(true)
		}
	}

	const canShowIntroduction = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (hideHomeIntroductionChannelIds.indexOf(Number(channelId)) > -1) {
			setShowIntroduction(false)
		} else {
			setShowIntroduction(true)
		}
	}

	const canShowWhiteBgTopMenu = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (showWhiteBgTopMenuChannelIds.indexOf(Number(channelId)) > -1) {
			setShowWhiteBgTopMenu(true)
		} else {
			setShowWhiteBgTopMenu(false)
		}
	}

	const canShowAllTopMenu = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (hideAllTopMenuChannelIds.indexOf(Number(channelId)) > -1) {
			setShowAllTopMenu(false)
		} else {
			setShowAllTopMenu(true)
		}
	}

	const canShowHomeIncome = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (hideHomeIncomeChannelIds.indexOf(Number(channelId)) > -1) {
			setShowHomeIncome(false)
		} else {
			setShowHomeIncome(true)
		}
	}

	const setShowOnlineCountBox = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (showOnlineCountChannelIds.indexOf(Number(channelId)) > -1) {
			setShowOnlineCount(true)
		} else {
			setShowOnlineCount(false)
		}
	}

	return (
		<IonPage className={styles.homePage}>
			{showDialog && (
				<DialogNotice hideDialog={() => setShowDialog(!showDialog)} hide={false}>
					<h3>{t('home.announcement')}</h3>
					<div className={styles.overflowNotice} dangerouslySetInnerHTML={{__html : homeNotice}} />
					<div className='btn-body'>
						<button
							onClick={() => setShowDialog(!showDialog)}
							className={`${themeValue === 'dark' ? 'btnBodyDark' : 'btnBodyLight'}`}
						>
							{t('home.close')}
						</button>
					</div>
				</DialogNotice>
			)}
			{showModal && (
				<DialogNotice hideDialog={() => setShowModal(!showModal)} hide={false}>
					<h3>{t('home.promotion')}</h3>
					<p>{t('home.promotion-content')}</p>
					<div className='btn-body' style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' , position: 'absolute', bottom: '20px'}}>
						<button
							onClick={() => setShowModal(!showModal)}
							className={`${themeValue === 'dark' ? 'btnBodyDark' : 'btnBodyLight'}`}
						>
							{t('home.close')}
						</button>
					</div>
				</DialogNotice>
			)}
			{/*<IonHeader className={styles.homeHeaderBackground}>*/}
			{/*	<IonToolbar>*/}
			{/*		<IonRow className="ion-justify-content-between ion-align-items-center">*/}
			{/*			<IonCol size="2"/>*/}
			{/*			<IonCol size="8">*/}

			{/*			</IonCol>*/}
			{/*			<IonCol size="2"/>*/}
			{/*		</IonRow>*/}
			{/*	</IonToolbar>*/}
			{/*</IonHeader>*/}
			<IonContent fullscreen>
				<IonGrid className={`ion-no-padding ${themeValue === 'dark' ? styles.homeBrandBackGroundDark : styles.homeBrandBackGroundLight}`}>
					<IonGrid className={`ion-no-padding-bottom ${styles.homeBrand}`}>
						<IonRow className="ion-justify-content-center ion-align-items-center ion-padding" style={{ marginTop: '20px' }}>
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.homeHeadTitleDark : styles.homeHeadTitleLight}`}>{t('home.title')}</h4>
						</IonRow>
						<IonRow style={{ marginTop: '20px' }}>
							<HomeSlider1 />
						</IonRow>
						<IonRow>
							<Slogan themeValue={themeValue} test={homeTopText} />
						</IonRow>
						{
							themeValue === 'dark' &&
							<IonCard className={`ion-padding ${styles.brandCard}`}>
							<IonRow className="ion-justify-content-between ion-align-items-center ion-padding-start ion-padding-end">
							<IonCol size="6">
							<span>{t('home.mybalance')}:</span>
							<h2 className={`main-number ${styles.balanceAmount}`}>$ {typeof assets.availableBalance === 'number' ? assets.availableBalance.toFixed(2) : assets.availableBalance}</h2>
							</IonCol>
							<IonRouterLink routerLink='/support'>
							<IonImg src="/assets/images/home/14@2x.png" />
							</IonRouterLink>
							</IonRow>
							</IonCard>
						}
						{showAllTopMenu &&
							<IonCard
								className={`${themeValue === 'dark' ? styles.homeMenuCardDark : styles.homeMenuCardLight}`}>
								<IonRow className={`ion-justify-content-center ion-margin-top ion-padding-top ion-margin-bottom ${styles.imageText} `}>
									<div
										className="ion-text-center"
										onClick={() => {
											router.push('/support')
										}}
									>
										<IonImg className={`${themeValue === 'dark' ? styles.homeMenuIconImgDark : styles.homeMenuIconImgLight}`} src="/assets/images/home/10@2x.png" />
										<span className={styles.menuLabel}>{t('home.coincharge')}</span>
									</div>
									<div
										onClick={() => {
											router.push('/withdraw')
										}}
									>
										<IonImg className={`${themeValue === 'dark' ? styles.homeMenuIconImgDark : styles.homeMenuIconImgLight}`} src="/assets/images/home/11@2x.png" />
										<span className={styles.menuLabel}>{t('home.cashwithdraw')}</span>
									</div>
									{showFinancialMenu &&
										<div onClick={() => setShowModal({ showModal: true })}
										>
											<IonImg className={`${themeValue === 'dark' ? styles.homeMenuIconImgDark : styles.homeMenuIconImgLight}`} src="/assets/images/home/13@2x.png" />
											<span className={styles.menuLabel}>{t('home.promotion')}</span>
										</div>
									}
									{showInviteMenu &&
										<div
											onClick={() => {
												router.push('/invite')
											}}
										>
											<IonImg className={`${themeValue === 'dark' ? styles.homeMenuIconImgDark : styles.homeMenuIconImgLight}`} src="/assets/images/home/12@2x.png" />
											<span className={styles.menuLabel}>{t('home.invite')}</span>
										</div>
									}
								</IonRow>
							</IonCard>
						}

						{showWhiteBgTopMenu &&
							<IonCard className={`${styles.whiteBgMenu}`} style={{background: 'white'}}>
								<div>
									<IonRow className={`ion-justify-content-start ion-align-items-center ion-margin-bottom ${styles.imageText} `}>
										<div
											className="ion-text-center"
											style={{marginLeft: '8px'}}
											onClick={() => {
												router.push('/withdraw')
											}}
										>
											<IonImg src="/assets/white-bg-menu-icon/5.png" />
											<span className={styles.menuLabelOfWitheBg} style={{color: '#3f3d3d'}}>{t('home.withdraw')}</span>
										</div>
										<div
											style={{marginLeft: '8px'}}
											onClick={() => {
												router.push('/app/account')
											}}
										>
											<IonImg src="/assets/white-bg-menu-icon/4.png" />
											<span className={styles.menuLabelOfWitheBg} style={{color: '#3f3d3d'}}>{t('home.wallet')}</span>
										</div>
										{showInviteMenu &&
											<div
												style={{marginLeft: '8px'}}
												onClick={() => {
													router.push('/invite')
												}}
											>
												<IonImg  src="/assets/white-bg-menu-icon/6.png" />
												<span className={styles.menuLabelOfWitheBg} style={{color: '#3f3d3d'}}>{t('home.invite')}</span>
											</div>
										}
									</IonRow>
								</div>
								<div>
									<IonRow className={`ion-justify-content-end ion-margin-bottom ${styles.imageText} `}>
										<div
											style={{marginRight: '8px'}}
											className="ion-text-center"
											onClick={() => {
												router.push('/support')
											}}
										>
											<IonImg src="/assets/white-bg-menu-icon/3.png" />
											<span className={styles.menuLabelOfWitheBg} style={{color: '#3f3d3d'}}>{t('home.deposit')}</span>
										</div>
										<div
											style={{marginRight: '8px'}}
											onClick={() => {
												router.push('/company-rules')
											}}
										>
											<IonImg src="/assets/white-bg-menu-icon/2.png" />
											<span className={styles.menuLabelOfWitheBg} style={{color: '#3f3d3d'}}>{t('home.rules')}</span>
										</div>
										{showInviteMenu &&
											<div
												style={{marginRight: '8px'}}
												onClick={() => {
													router.push('/company-profile')
												}}
											>
												<IonImg src="/assets/white-bg-menu-icon/1.png" />
												<span className={styles.menuLabelOfWitheBg} style={{color: '#3f3d3d'}}>{t('home.platform')}</span>
											</div>
										}
									</IonRow>
								</div>
							</IonCard>
						}
					</IonGrid>
				</IonGrid>
				<IonGrid className={`ion-no-padding ion-no-margi`}>
					{homeDirectDisplayArr.map((item, index) => {
						return (
							<IonCard
								className={`ion-no-padding ion-no-margin ${styles.homeDirectDisplayCard}`}
								key={index}
							>
								<img className={`${styles.homeDirectDisplayImg}`} src={item.bgUrl} />
								<div className={`${styles.homeDirectDisplayCardTitle} ${themeValue === 'dark' ? styles.homeDirectDisplayCardTitleDark : styles.homeDirectDisplayCardTitleLight}`}>{item.graphicTitle}</div>
								<div onClick={() => {
									router.push('/app/order')
								}} className={`${styles.homeDirectDisplayCardLinkText} ${themeValue === 'dark' ? styles.homeDirectDisplayCardLinkTextDark : styles.homeDirectDisplayCardLinkTextLight}`}>{t('order.start-order')}</div>
							</IonCard>
						)
					})}
				</IonGrid>
				{showHomeIncome &&
					<IonRow
						className={`main-padding ion-margin-top ion-margin-bottom ${styles.homeCardGroup}`}
						style={{ display: 'flex', justifyContent: 'space-around' }}
					>
						<div style={{ width: '45%' }}>
							<IonCard className={`ion-text-center ion-padding ion-no-margin ${themeValue === 'dark' ? styles.homeCardGroupDark : styles.homeCardGroupLight}`}>
								<span>{t('home.commisiontoday')}</span>
								<h5 className="main-number">$ {typeof assets.commissionAmountByToday === 'number' ? assets.commissionAmountByToday.toFixed(2) : assets.commissionAmountByToday
								}</h5>
							</IonCard>
						</div>
						<div style={{ width: '45%' }}>
							<IonCard className={`ion-text-center ion-padding ion-no-margin ${themeValue === 'dark' ? styles.homeCardGroupDark : styles.homeCardGroupLight}`}>
								<span>{t('home.totalrevenue')}</span>
								<h5 className="main-number">$ {typeof assets.totalIncomeAmount === 'number' ? assets.totalIncomeAmount.toFixed(2) : assets.totalIncomeAmount
								}</h5>
							</IonCard>
						</div>
					</IonRow>
				}
				<IonGrid className={`main-padding ${styles.membershipLevelSection}`}>
					<IonRow class={`ion-align-items-center ${styles.sectionTitle}`}>
						<div className={`${themeValue === 'dark' ? styles.sectionTitleDark : styles.sectionTitleLight}`}></div>
						<span className={`main-padding ${themeValue === 'dark' ? styles.sectionTitleSpanDark : styles.sectionTitleSpanLight}`}>{t('home.membership-level.title')}</span>
					</IonRow>
					<IonGrid>
						{levelArr.map((item, index) => {
							return (
								<IonCard
									className={`ion-padding ion-no-margin ${styles.membershipCard}`}
									key={index}
									style={{ backgroundImage: `url(${item.bgUrl})`, color: item.textColor ? item.textColor : '#ffffff' }}
								>
									<IonRow className="ion-justify-content-between ion-align-items-start">
										<IonCol size="8">
											<IonRow className="ion-align-items-center">
												<IonImg src={item.iconUrl} />
											</IonRow>
											<IonRow className="ion-align-items-center">
												<div>
													<h4>{item.levelName}</h4>
													<p>{t('home.membership-level.min-balance')} ${item.minBalance.toFixed(2)}</p>
													<p>
														{t('home.membership-level.commission')} {item.commissionRate}% | {item.maxOrderNumber} {t('home.membership-level.orders')}
													</p>
												</div>
											</IonRow>
										</IonCol>
										<IonCol size="3" className={`${styles.rightBox}`}>
											<IonRow>
												{memberInfo.memberLevelIndex <= item.orderNum ? memberInfo.memberLevelId === item.id || memberInfo.memberLevelIndex === item.orderNum ? <span>{t('home.membership-level.current-level')}</span> : <IonRouterLink routerLink='/support' style={{color: item.textColor ? item.textColor : '#ffffff'}}>
													<span style={{color: '#ffffff'}}>{t('home.membership-level.join')}</span>
												</IonRouterLink> : ''}
											</IonRow>
											{showOnlineCount &&
												<IonCol className={`${styles.rightBoxOnlineCount}`}>
													<IonRow>
														<div style={{color: '#ffffff'}}>{t('home.membership-level.online-count')}</div>
													</IonRow>
													<IonRow>
														<AnimateNumber value={item.onlineCount} />
													</IonRow>
												</IonCol>
											}
										</IonCol>
									</IonRow>
								</IonCard>
							)
						})}
					</IonGrid>
				</IonGrid>
				<IonGrid>
					{homeDirectDisplayArr2.map((item, index) => {
						return (
							<IonCard
								className={`ion-no-padding ion-no-margin ${styles.homeDirectDisplayCard}`}
								key={index}
							>
								<img className={`${styles.homeDirectDisplayImg}`} src={item.bgUrl} />
								<div className={`${styles.homeDirectDisplayCardTitle} ${themeValue === 'dark' ? styles.homeDirectDisplayCardTitleDark : styles.homeDirectDisplayCardTitleLight}`}>{item.graphicTitle}</div>
								<div onClick={() => {
									router.push('/app/order')
								}} className={`${styles.homeDirectDisplayCardLinkText} ${themeValue === 'dark' ? styles.homeDirectDisplayCardLinkTextDark : styles.homeDirectDisplayCardLinkTextLight}`}>{t('order.start-order')}</div>
							</IonCard>
						)
					})}
				</IonGrid>
				<IonGrid className={`main-padding ${styles.membershipIncommeSection}`}>
					<IonRow class={`ion-align-items-center ${styles.sectionTitle}`}>
						<div className={`${themeValue === 'dark' ? styles.sectionTitleDark : styles.sectionTitleLight}`}></div>
						<span className={`main-padding ${themeValue === 'dark' ? styles.sectionTitleSpanDark : styles.sectionTitleSpanLight}`}>{t('home.membership-income.title')}</span>
					</IonRow>
					<IonGrid>
						<CssRoll themeValue={themeValue} label={t('home.membership-income.amount')} />
					</IonGrid>
				</IonGrid>
				<IonGrid className={`ion-no-padding ion-margin-top ${styles.homeBottomBackground} ${themeValue === 'dark' ? styles.homeBottomBackgroundDark : styles.homeBottomBackgroundLight}`}>
					{showIntroduction &&
						<IonGrid className={`ion-padding-top ${styles.homeIntroduction}`}>
							<IonGrid className={styles.accordionBox}>
								<IonRow class={`ion-align-items-center ion-padding-start ${styles.sectionTitle}`}>
									<div className={`${themeValue === 'dark' ? styles.sectionTitleDark : styles.sectionTitleLight}`}></div>
									<span className={`main-padding ${themeValue === 'dark' ? styles.sectionTitleSpanDark : styles.sectionTitleSpanLight}`}>{t('home.introduction.title')}</span>
								</IonRow>
								<IonRow className={`ion-no-padding ${styles.companyInfoContainer}`}>
									<div className={`${styles.containerCol}`}>
										{introduceArr.map((item, index) => {
											return (
												<IonRouterLink routerLink={'/company-rules?id='+ `${item.id}`} key={item.id}>
													<div className={`${styles.contentItems}`}>
														<IonImg src={item.bgUrl} />
														<IonImg src={item.iconUrl} className={`${styles.lageImg}`} />
														<span className={styles.titleLabel}>{item.graphicTitle}</span>
													</div>
												</IonRouterLink>
											)
										})}
									</div>
									<div className={`${styles.containerCol}`}>
										{introduceArr2.map((item, index) => {
											return (
												<IonRouterLink routerLink={'/company-rules?id='+ `${item.id}`} key={item.id}>
													<div className={`${styles.contentItems}`}>
														<IonImg src={item.bgUrl} />
														<IonImg src={item.iconUrl} className={`${styles.smallImg}`} />
														<span className={styles.titleLabel}>{item.graphicTitle}</span>
													</div>
												</IonRouterLink>
											)
										})}
									</div>
								</IonRow>
							</IonGrid>
						</IonGrid>
					}
					<IonGrid className={`ion-no-padding ion-no-margin ${styles.homeBottomBackground2} ${themeValue === 'dark' ? styles.homeBottomBackground2Dark : styles.homeBottomBackground2Light}`}>
						<IonGrid className={`ion-padding-top ion-padding-bottom ${styles.homeIntroduction}`}>
							<IonGrid className={styles.homePatners}>
								<IonRow class={`ion-align-items-center ${styles.sectionTitle}`}>
									<div></div>
									<span className={`main-padding ${themeValue === 'dark' ? styles.partnerLabelDark : styles.partnerLabelLight}`}>{t('home.partner')}</span>
								</IonRow>
								<IonRow className="ion-align-items-center">
									{linksImage.map((item, index) => {
										return (
											<IonCol size="4" key={index}>
												<IonImg src={item} loading="lazy" alt="partner-img" style={{ transform: 'scale(.9)' }} />
											</IonCol>
										)
									})}
								</IonRow>
							</IonGrid>
						</IonGrid>
					</IonGrid>
				</IonGrid>
			</IonContent>
		</IonPage>
	)
}

export default Home
