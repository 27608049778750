import styles from './WithdrawRecord.module.scss'

import {
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonPage,
	IonRow,
	IonToolbar,
	IonCard, IonButton, IonIcon, IonInfiniteScrollContent, IonInfiniteScroll
} from '@ionic/react'
import { chevronBackOutline } from 'ionicons/icons'

import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import {useHistory, useLocation} from 'react-router'
import { CashRecordApi } from '../../request/api'
import BottomLine from '../../components/bottom-line/BottomLine'
import {formatTimeByTimezone} from "../../data/utils";
import {
	channelIdKey,
	defaultTheme,
	hideWithdrawRecordTimeChannelIds,
	limitDepositAndWithdrawRecordChannelIds,
	themeKey
} from "../../data/constant";
import {useRaceConditions} from "../../hooks";

const WithdrawRecord = () => {
	const { t, i18n } = useTranslation('lang')
	const { pathname } = useLocation()
	const [dataArr, setDataArr] = useState([])
	const [pageNum, setPageNum] = useState(1)
	const [pageSize, setPageSize] = useState(10)
	const [noDataStatus, setNoDataStatus] = useState(true)
	const [isLimitDepositAndWithdrawRecord, setIsLimitDepositAndWithdrawRecord] = useState(false)

	const themeValue = localStorage.getItem(themeKey) || defaultTheme

	const history = useHistory()

	const [showRecordTime, setShowRecordTime] = useState(true)

	useEffect(() => {
		if (localStorage.getItem('Authorization') === null) history.push('/login')
		handleIsLimitDepositAndWithdrawRecord()
	}, [])

	useRaceConditions(() => {
		isCanShowRecordTime()
	}, [pathname])

	const isCanShowRecordTime = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (hideWithdrawRecordTimeChannelIds.indexOf(Number(channelId)) > -1) {
			setShowRecordTime(false)
		} else {
			setShowRecordTime(true)
		}
	}

	const getCashRecord = (pageNum) => {
		CashRecordApi({transactionType: '2', pageNum: pageNum, pageSize: pageSize}).then(res => {
			if (res.code === 200) {
				const { data } = res
				if (res.data.length > 0) {
					if (pageNum === 1) {
						// 是否需要过滤日期
						setDataArr(filterRecordByTime(data))
					} else {
						setDataArr(dataArr.concat(filterRecordByTime(data)))
					}
					if (!isLimitDepositAndWithdrawRecord) setNoDataStatus(false)
				} else {
					setNoDataStatus(true)
				}
			}
		})
	}

	const loadData = (event) => {
		setTimeout(() => {
			event.target.complete()
			if (noDataStatus) {
				event.target.disabled = true
			} else {
				setPageNum(pageNum + 1)
				CashRecordApi(pageNum + 1)
			}
		}, 500)
	}

	const goBack = () => {
		history.goBack()
	}

	const handleIsLimitDepositAndWithdrawRecord = () => {
		let channelId = localStorage.getItem(channelIdKey)
		if (limitDepositAndWithdrawRecordChannelIds.indexOf(Number(channelId)) > -1) {
			setIsLimitDepositAndWithdrawRecord(true)
		} else {
			setIsLimitDepositAndWithdrawRecord(false)
		}
		getCashRecord(pageNum)
	}

	const filterRecordByTime = (data) => {
		handleIsLimitDepositAndWithdrawRecord()
		if (!isLimitDepositAndWithdrawRecord) {
			return data;
		} else {
			let list = [];
			let nowTimeStamp = new Date().getTime();
			// if (dataArr.length > 0) {
			// 	nowTimeStamp = new Date(dataArr[0].createTime).getTime()
			// } else {
			// 	nowTimeStamp = new Date(data[0].createTime).getTime()
			// }
			for (let i = 0; i < data.length; i++) {
				if (nowTimeStamp - new Date(data.createTime).getTime() <= 3 * 24 * 60 * 60 * 1000) {
					list.push(data[i])
				}
			}
			if (data.length >= list.length) {
				setNoDataStatus(true)
			}
			// if (pageNum === 1 && data.length === 1) {
			// 	list = data
			// 	setNoDataStatus(true)
			// }
			if (list.length < pageSize) setNoDataStatus(true)
			return list
		}
	}

	return (
		<IonPage className={styles.withdrawRocordPage}>
			<IonHeader className={`${themeValue === 'dark' ? styles.headerDark : styles.headerLight}`}>
				<IonToolbar>
					<IonRow className="ion-justify-content-between ion-align-items-center">
						<IonCol size="2">
							<IonButtons>
								<IonButton onclick={() => goBack()}>
									<IonIcon style={{color: themeValue === 'dark' ? 'white' :'black'}} slot={'icon-only'} icon={chevronBackOutline}/>
								</IonButton>
							</IonButtons>
						</IonCol>
						<IonCol size="8">
							<h4 className={`main-title ion-text-center ion-no-margin ${themeValue === 'dark' ? styles.headerTitleDark : styles.headerTitleLight}`}>{t('withdraw-record.title')}</h4>
						</IonCol>
						<IonCol size="2"></IonCol>
					</IonRow>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen className="ion-padding">
				<IonGrid className="ion-padding-start ion-padding-end">
					<p style={{color: themeValue === 'dark' ? 'white' :'black'}}>{t('withdraw-record.title')}</p>
					{dataArr.map((item, index) => {
						return (
							<IonCard className={`ion-no-margin ion-padding main-radius ${themeValue === 'dark' ? styles.ionCardDark : styles.ionCardLight}`} key={index}>
								<IonRow className="ion-justify-content-between ion-align-items-center">
									<span>
										{t('recharge-record.order-number')}: {item.orderSn}
									</span>
									<span className={`main-number ${styles.totalAmount}`}>$ {item.swapAmount}</span>
								</IonRow>
								<IonRow className="ion-justify-content-between ion-align-items-center ion-margin-top">
									{showRecordTime &&
										<span className={styles.date}>
											{t('recharge-record.time')}: {formatTimeByTimezone(item.createTime)}
										</span>
									}
									{item.status === '1' && <span className={styles.statusBadge}>{t('recharge-record.seccessful')}</span>}
									{item.status === '0' && <span className={styles.statusBadge2}>{t('recharge-record.reviewing')}</span>}
									{item.status === '2' && <span className={styles.statusBadge3}>{t('recharge-record.review-rejection')}</span>}
								</IonRow>
							</IonCard>
						)
					})}
					{dataArr.length === 0 ? <h4 style={{ textAlign: 'center', color: 'white' }}>{t('common-text.no-data')}</h4> : null}
					{isLimitDepositAndWithdrawRecord && dataArr.length ?
						<h5 style={{color: '#cccccc', fontSize: '12px', textAlign: 'center'}}>{t('common-text.limit-deposit-record-tip')}</h5>
						: null}
				</IonGrid>
				<IonInfiniteScroll threshold="10px" distance="1%" onIonInfinite={(e) => loadData(e)}>
					<IonInfiniteScrollContent loadingSpinner="bubbles" loadingText={t('common-text.load-more-data')}>
					</IonInfiniteScrollContent>
				</IonInfiniteScroll>
			</IonContent>
			<BottomLine />
		</IonPage>
	)
}

export default WithdrawRecord
